
import { Vue, Component, Watch } from 'vue-property-decorator'
import widget from '@/store/modules/widget'
import { isNotEmptyInput } from '@/utils/validators'
import { validateRules, validationRules } from '@/utils/rules'
import WidgetTripTypeCard from '@/components/WidgetTripTypeCard.vue'
import WidgetPageHeader from '@/components/WidgetPageHeader.vue'
@Component({
  components: { WidgetTripTypeCard, WidgetPageHeader },
})
export default class WidgetTripDetails extends Vue {
  @Watch('tripTypeId')
  onTripTypeIdChange(): void {
    this.hideTripTypeError = true
  }

  isNotEmptyInput = isNotEmptyInput
  widget = widget
  rules = validationRules

  tripType = null
  passengers = null
  hideTripTypeError = true

  get tripTypeId(): number {
    return this.widget.tripTypeId
  }

  async next(): Promise<void> {
    let formIsValid = await validateRules(this)

    if (this.tripTypeId == null) {
      formIsValid = false
      this.hideTripTypeError = false
    }
    if (!formIsValid) {
      return
    }
    widget.nextPage()
  }
}
