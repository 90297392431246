import { Action, Module, VuexModule } from 'vuex-class-modules'

import store from '@/store/index'
import { Company } from '@/models/dto/Company'
import { VehicleType } from '@/models/dto/Vehicle'
import { Trip, TripType } from '@/models/dto/Trip'
import { staticResource } from '@/utils/env'
import { Address, Stop } from '@/models/dto'
import { EventType } from '@/models/EventType'
import { Quote } from '@/models/dto/Quote'
import dayjs from 'dayjs'
import widget from '@/services/widget'
import { formattedPhoneToNumber, phoneFormatFilter } from '@/utils/string'
import { metersToMiles } from '@/utils/distance'
import { Customer } from '@/models/dto/Customer'

const newVehicle = { id: null, quantity: 1 }

@Module({ generateMutationSetters: true })
class WidgetModule extends VuexModule {
  _company: Partial<Company> = null
  _page = 1
  _PAGE_COUNT = 6
  _MAX_FILES_ALLOWED = 3
  _success = false
  _loading = false
  _identifier = ''
  _customer = {
    firstName: '',
    lastName: '',
    phone: '',
    email: '',
    customerAccount: {
      name: '',
    },
  }

  // Trip info page
  _tripTypes: TripType[] = []
  _tripTypeId = null
  _passengerCount: string = null
  _stops = [new Stop()]
  _finalDropoffAddress: Address = null
  _finalDropoffEstimation = '--'
  _finalDropoffDatetime = null
  _distanceEstimation = '--'
  _durationEstimation = '--'
  _attachedFiles: any[] = []

  // Optional selections page
  _vehicleTypes: VehicleType[] = []
  _vehicles: { id: number; quantity: number }[] = [newVehicle]
  _note: string = null
  _tag: string = null

  // Confirm request page
  _firstName: string = null
  _lastName: string = null
  _email: string = null
  _phone: string = null
  _customerAccountName: string = null

  // Thank you page
  _eventTypeId: number = null

  get loading(): boolean {
    return this._loading
  }

  get company(): Partial<Company> {
    return this._company
  }

  get pageCount(): number {
    return this._PAGE_COUNT
  }

  get maxFilesAllowed(): number {
    return this._MAX_FILES_ALLOWED
  }

  get page(): number {
    return this._page
  }

  get isTripDetailsPage(): boolean {
    return this._page === 1
  }

  get isTripItineraryPage(): boolean {
    return this._page === 2
  }

  get isOptionalSelectionsPage(): boolean {
    return this._page === 3
  }

  get isConfirmRequestPage(): boolean {
    return this._page === 4
  }

  get isEventTypesPage(): boolean {
    return this._page === 5
  }

  get isThankYouPage(): boolean {
    return this._page === 6
  }

  get companyLogo(): string {
    return staticResource(this._company?.darkLogoUrl)
  }

  get companyWebsiteUrl(): string {
    return this._company?.websiteUrl
  }

  get companyPhone(): string {
    return this._company?.phone
  }

  get companyEmail(): string {
    return this._company?.email
  }

  get companyName(): string {
    return this._company?.name
  }

  get tag(): string {
    return this._tag
  }

  get primaryColor(): string {
    return this._company?.primaryColor || '#000000'
  }

  get primaryColor20(): string {
    const primaryColor = this._company?.primaryColor || '#000000'

    // Convert the primary color from hex to RGB
    const hex = primaryColor[0] === '#' ? primaryColor.slice(1) : primaryColor
    const bigint = parseInt(hex, 16)
    const r = (bigint >> 16) & 255
    const g = (bigint >> 8) & 255
    const b = bigint & 255

    // Return the primary color with 20% opacity
    return `rgba(${r}, ${g}, ${b}, 0.2)`
  }

  get secondaryColor(): string {
    return this._company?.secondaryColor || '#000000'
  }

  get tripTypes(): TripType[] {
    return this._tripTypes || []
  }

  get tripTypeId(): number {
    return this._tripTypeId
  }

  get eventTypes(): EventType[] {
    return this._company?.eventTypes || []
  }

  get tripType(): string {
    return this.tripTypes?.find((t) => t.id === this.tripTypeId)?.label || null
  }

  get eventTypeId(): number {
    return this._eventTypeId
  }

  get passengerCount(): string {
    return this._passengerCount
  }

  get vehicleTypes(): VehicleType[] {
    return this._vehicleTypes || []
  }

  get vehicles(): { id: number; quantity: number }[] {
    return this._vehicles
  }

  get stops(): Stop[] {
    return this._stops
  }

  get finalDropoffAddress(): Address {
    return this._finalDropoffAddress
  }

  get attachedFiles(): any[] {
    return this._attachedFiles
  }

  get success(): boolean {
    return this._success
  }

  get note(): string {
    return this._note
  }

  get finalDropoffEstimation(): string {
    return this._finalDropoffEstimation
  }

  get finalDropoffDatetime(): string {
    return this._finalDropoffDatetime
  }

  get durationEstimation(): string {
    return this._durationEstimation
  }

  get distanceEstimation(): string {
    return this._distanceEstimation
  }

  get firstName(): string {
    return this._firstName
  }

  get lastName(): string {
    return this._lastName
  }

  get email(): string {
    return this._email
  }

  get phone(): string {
    return phoneFormatFilter(this._phone)
  }

  get customerAccountName(): string {
    return this._customerAccountName
  }

  get allStops(): { stops: Stop[] } {
    const stops = this._stops.map((s, i) => ({ ...s, orderIndex: i }))
    return {
      stops: [
        ...stops,
        new Stop({
          address: this.finalDropoffAddress,
          dropoffDatetime: this._finalDropoffDatetime,
          orderIndex: this._stops.length,
        }),
      ],
    }
  }

  get customer(): Partial<Customer> {
    return this._customer
  }

  get identifier(): string {
    return this._identifier
  }

  get isLastPage(): boolean {
    return this._page === this._PAGE_COUNT
  }

  get isRoundTrip(): boolean {
    const ROUND_TRIP_TYPE_ID = 2
    return this._tripTypeId === ROUND_TRIP_TYPE_ID
  }

  @Action
  setIdentifier(identifier: string): void {
    this._identifier = identifier
  }

  @Action
  setCompany(company: Partial<Company>): void {
    this._company = company
    this._vehicleTypes = (company.vehicleTypes || [])
      .filter((v) => !!v.active)
      .map((v) => ({ ...v, id: v.vehicleTypeId }))
  }

  @Action
  setTripTypes(tripTypes: TripType[]): void {
    this._tripTypes = tripTypes || []
  }

  @Action
  setTripType(tripTypeId: number): void {
    const oldTripType = this._tripTypeId
    const ROUND_TRIP_TYPE_ID = 2
    this._tripTypeId = tripTypeId
    if (tripTypeId === ROUND_TRIP_TYPE_ID) {
      this.setRoundTrip()
    } else if (
      oldTripType === ROUND_TRIP_TYPE_ID &&
      tripTypeId !== ROUND_TRIP_TYPE_ID
    ) {
      this.removeRoundTrip()
    }
  }

  @Action
  setEventType(eventTypeId: number): void {
    this._eventTypeId = eventTypeId
  }

  @Action
  setPassengerCount(passengerCount: string): void {
    this._passengerCount = passengerCount
  }

  @Action
  setVehicleTypes(vehicleTypes: VehicleType[]): void {
    this._vehicleTypes = vehicleTypes || []
  }

  @Action
  setVehicles(vehicles: { id: number; quantity: number }[]): void {
    this._vehicles = vehicles
  }

  @Action
  setTag(tag: string): void {
    this._tag = tag
  }

  @Action
  addNewVehicle(): void {
    this._vehicles = [...this.vehicles, newVehicle]
  }

  @Action
  setVehicleLabel(event: { id: number; idx: number }): void {
    this._vehicles.splice(event.idx, 1, {
      id: event.id,
      quantity: this._vehicles[event.idx].quantity,
    })
  }

  @Action
  setVehicleQuantity(event: { quantity: number; idx: number }): void {
    if (event.quantity === 0) {
      this._vehicles.splice(event.idx, 1)
    } else {
      this._vehicles.splice(event.idx, 1, {
        id: this._vehicles[event.idx].id,
        quantity: event.quantity,
      })
    }
  }

  @Action
  setPage(page: number): void {
    this._page = page
  }

  @Action
  nextPage(): void {
    if (this._page >= this.pageCount + 1) {
      return
    }
    this._page = this._page + 1
  }

  @Action
  lastPage(): void {
    this._page = this._PAGE_COUNT
  }

  @Action
  prevPage(): void {
    this._page = Math.max(this._page - 1, 0)
  }

  @Action
  addStop(): void {
    this._stops.push(new Stop())
    this.getStopDropoffEstimations()
  }

  @Action
  removeStop(idx: number): void {
    this._stops.splice(idx, 1)
    this.getStopDropoffEstimations()
  }

  @Action
  updateStop(data: { idx: number; stop: Partial<Stop> }): void {
    this._stops.splice(data.idx, 1, { ...this._stops[data.idx], ...data.stop })
    this.getStopDropoffEstimations()

    if (data.idx === 0 && this.isRoundTrip) {
      this.setRoundTrip()
    }
  }

  @Action
  removeRoundTrip(): void {
    // If there are three stops and the middle stop is empty, remove it
    if (this._stops.length === 2 && !this._stops[1].address) {
      this._stops.splice(1, 1)
      this.getStopDropoffEstimations()
      return
    }
  }

  @Action
  setRoundTrip(): void {
    const { address: firstAddress } = this._stops[0]
    const lastAddress = this._finalDropoffAddress
    const firstStopMatchesLastStop =
      firstAddress?.completeAddress === lastAddress?.completeAddress

    // If the first and last stops are already the same, add a middle stop if needed
    if (firstStopMatchesLastStop) {
      if (this._stops.length <= 1) {
        this._stops.splice(1, 0, new Stop())
      }

      this.getStopDropoffEstimations()
      return
    }

    // If there is a final address but no first address, remove the final address
    if (this._finalDropoffAddress && !this._stops[0].address) {
      this._finalDropoffAddress = null
      this.getStopDropoffEstimations()
      return
    }

    if (!this._stops[0].address) {
      return
    }

    // If there's already a final dropoff that's different, set the final
    // dropoff as the second-to-last stop instead
    if (this._finalDropoffAddress && this._stops[0].address) {
      const stop = new Stop()
      stop.address = this._finalDropoffAddress

      this._stops.splice(this._stops.length, 0, stop)
      this._finalDropoffAddress = { ...this._stops[0].address }
      this.getStopDropoffEstimations()
      return
    }

    // Otherwise, there isn't a final dropoff address -- set the final
    // dropoff to be the same as the first stop, and add a middle stop
    // if needed
    this._finalDropoffAddress = { ...this._stops[0].address }

    if (this._stops.length <= 1) {
      this._stops.splice(this._stops.length, 0, new Stop())
    }

    this.getStopDropoffEstimations()
  }

  @Action
  setFinalDropoffAddress(address: Address) {
    this._finalDropoffAddress = address
    this.getStopDropoffEstimations()
    if (this.isRoundTrip) {
      this.setRoundTrip()
    }
  }

  async getStopDropoffEstimations(): Promise<void> {
    try {
      this._loading = true
      const estimation = await widget.tripEstimations(
        this.getQuoteForEstimation()
      )
      const estimationData = estimation?.data?.[0]?.timesFromPreviousStop
      if (!estimationData?.length) {
        return
      }

      for (let i = 1; i < estimationData.length; i++) {
        const isLastStop = i === estimationData.length - 1
        const stop = this._stops[i]
        const previousStop = this._stops[i - 1]
        const previousStopPickupDatetime = previousStop?.pickupDatetime
        const previousStopAddress = previousStop?.address

        // If the previous stop is missing a pickup datetime or address, or
        // there's no estimation for the current stop, null out the dropoff
        // datetime and continue
        if (
          !previousStopPickupDatetime ||
          !previousStopAddress ||
          estimationData[i] == null
        ) {
          if (stop) {
            stop.dropoffDatetime = null
            stop.dropoffDate = null
            stop.dropoffTime = null
          } else if (isLastStop) {
            this._finalDropoffEstimation = '--'
            this._finalDropoffDatetime = null
          }
          continue
        }

        const newPickupDatetime = dayjs(previousStopPickupDatetime).add(
          estimationData[i],
          'seconds'
        )

        // If we're on the last stop, set the final dropoff datetime and estimation
        // Otherwise, set the dropoff datetime for the current stop
        if (isLastStop && this._finalDropoffAddress) {
          const endDate = newPickupDatetime

          // We can't pull the mixin into Vuex modules, so using dayjs here
          this._finalDropoffEstimation = dayjs(endDate.toISOString())
            .tz(this._finalDropoffAddress.timeZone)
            .format('ddd, M/D/YY, h:mm A z')
          this._finalDropoffDatetime = endDate.toISOString()
        } else {
          stop.dropoffDatetime = newPickupDatetime.toISOString()
          stop.dropoffDate = newPickupDatetime.format('YYYY-MM-DD')
          stop.dropoffTime = newPickupDatetime.format('HH:mm:ss')
        }
      }

      if (estimation.data[0]) {
        const { distance, itineraryHours } = estimation.data[0]
        this._distanceEstimation = metersToMiles(distance).toFixed(1)
        this._durationEstimation = ((itineraryHours || 0) + 1).toFixed(0)
      }
    } catch (e) {
      console.error(e)
      this._finalDropoffEstimation = '--'
      this._finalDropoffDatetime = null
      this._distanceEstimation = '--'
      this._durationEstimation = '--'
    } finally {
      this._loading = false
    }
  }

  getQuoteForEstimation(): Quote {
    return new Quote({
      trips: [
        new Trip({
          stops: [
            ...this._stops.map((s, i) => {
              return new Stop({
                orderIndex: i,
                address: s.address,
                pickupDatetime: s.pickupDate
                  ? dayjs(s.pickupDatetime).toISOString()
                  : null,
              })
            }),
            new Stop({
              orderIndex: this._stops.length,
              address: this._finalDropoffAddress,
            }),
          ],
        }),
      ],
    })
  }

  @Action
  setFirstName(firstName: string) {
    this._firstName = firstName
  }

  @Action
  setLastName(lastName: string) {
    this._lastName = lastName
  }

  @Action
  setEmail(email: string) {
    this._email = email
  }

  @Action
  setPhone(phone: string) {
    this._phone = formattedPhoneToNumber(phone)
  }

  @Action
  setCustomerAccountName(customerAccountName: string) {
    this._customerAccountName = customerAccountName
  }

  @Action
  setSuccess(success: boolean): void {
    this._success = success
  }

  setNote(note: string) {
    this._note = note
  }

  @Action
  addItineraryAttachment(attachment: any): void {
    this._attachedFiles.push(attachment)
  }

  @Action
  removeItineraryAttachment(fileIndex: number): void {
    this._attachedFiles.splice(fileIndex, 1)
  }

  @Action
  setCustomerFirstName(firstName: string): void {
    this._customer.firstName = firstName
  }

  @Action
  setCustomerLastName(lastName: string): void {
    this._customer.lastName = lastName
  }

  @Action
  setCustomerPhone(phone: string): void {
    this._customer.phone = phone
  }

  @Action
  setCustomerEmail(email: string): void {
    this._customer.email = email
  }

  @Action
  setCustomerCompanyName(companyName: string): void {
    this._customer.customerAccount.name = companyName
  }

  @Action
  setLoading(loading: boolean): void {
    this._loading = loading
  }
}

export default new WidgetModule({ store, name: 'widget' })
