
import { Vue, Component, Prop } from 'vue-property-decorator'
import widget from '@/store/modules/widget'

@Component({})
export default class WidgetPageHeader extends Vue {
  @Prop({ type: String, required: true }) title!: string
  @Prop({ type: String, required: false, default: '' }) buttonId!: string
  @Prop({ type: Boolean, default: false }) hideBackBtn!: boolean

  widget = widget
}
