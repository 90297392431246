
import { Prop } from 'vue-property-decorator'
import DateMixin from '@/mixins/DateMixin'
import Component, { mixins } from 'vue-class-component'
import widget from '@/store/modules/widget'
import AutocompleteAddress from '@/components/AutocompleteAddress.vue'
import { Address } from '@/models/dto/Address'
import { Stop } from '@/models/dto'
import { isNotEmptyInput } from '@/utils/validators'
import { validateRules, validationRules } from '@/utils/rules'
import WidgetItineraryUpload from './WidgetItineraryUpload.vue'
import dayjs, { OpUnitType } from 'dayjs'
import WidgetPageHeader from '@/components/WidgetPageHeader.vue'

@Component({
  components: {
    AutocompleteAddress,
    WidgetItineraryUpload,
    WidgetPageHeader,
  },
})
export default class TripInfo extends mixins(DateMixin) {
  isNotEmptyInput = isNotEmptyInput
  widget = widget
  rules = validationRules

  tripType = null
  date = []
  time = null
  passengers = null
  address = new Address()

  get allButLastStop(): Stop[] {
    if (widget.stops.length <= 1) {
      return []
    }
    return widget.stops.slice(0, -1)
  }

  get finalDropoffDatetime(): string {
    const finalDropoffDatetime = widget.finalDropoffDatetime
    const finalAddressTz = widget.finalDropoffAddress?.timeZone
    if (!finalDropoffDatetime || !finalAddressTz) {
      return '--'
    }

    const weekday = dayjs(finalDropoffDatetime).tz(finalAddressTz).format('ddd')

    const date = this.formatShortDate(finalDropoffDatetime, {
      tz: finalAddressTz,
    })
    const time = this.formatShortTime(finalDropoffDatetime, {
      tz: finalAddressTz,
      showMeridianUpper: true,
    })

    return `${weekday}, ${date}, ${time}`
  }

  get dropoffTitle(): string {
    if (widget.finalDropoffAddress) {
      return widget.finalDropoffAddress.title
    }

    return 'Where does this trip end?'
  }

  get isRoundTrip(): boolean {
    return widget.isRoundTrip
  }

  isDatePickerValid(date: string, stopIdx: number): boolean | string {
    if (!date && stopIdx === 0) {
      return 'Pickup date is required'
    }
    if (!date) {
      return 'Stop date is required'
    }

    return this.validateTime(stopIdx, 'day')
  }

  isTimePickerValid(time: string, stopIdx: number): boolean | string {
    if (!time) {
      return stopIdx === 0 ? 'Pickup time is required' : 'Stop time is required'
    }
    return this.validateTime(stopIdx, 'minute', false)
  }

  validateTime(
    stopIdx: number,
    unit: OpUnitType,
    isDatePicker = true
  ): boolean | string {
    const currentStop = widget.stops[stopIdx]

    let adjustedCurrentStopPickup = dayjs(currentStop.pickupDatetime)
    if (currentStop?.address?.timeZone) {
      adjustedCurrentStopPickup = adjustedCurrentStopPickup.tz(
        currentStop.address.timeZone
      )
    }

    if (isDatePicker && adjustedCurrentStopPickup.isBefore(dayjs(), unit)) {
      if (stopIdx === 0) {
        return 'Pickup date must be in the future'
      } else {
        return 'Stop date must be in the future'
      }
    }

    if (stopIdx === 0) {
      return true
    }

    if (
      dayjs(currentStop.pickupDatetime).isBefore(
        currentStop.dropoffDatetime,
        unit
      )
    ) {
      return 'Departure cannot be before arrival time'
    }

    const prevStop = widget.stops[stopIdx - 1]
    if (prevStop) {
      let adjustedPrevStopPickup = dayjs(prevStop.pickupDatetime)
      if (prevStop?.address?.timeZone) {
        adjustedPrevStopPickup = adjustedPrevStopPickup.tz(
          prevStop.address.timeZone
        )
      }

      if (adjustedCurrentStopPickup.isBefore(adjustedPrevStopPickup, unit)) {
        return 'Stop date cannot be before previous stop'
      }
    }

    return true
  }

  getStopTitle(index: number): string {
    const stop = widget.stops[index]
    if (stop.address) {
      return stop.address.title
    }

    if (index === 0) {
      return 'Where are we picking you up?'
    }

    return 'Where are you going?'
  }

  getDatePickerLabel(idx: number): string {
    const stop = widget.stops[idx]
    const label = idx === 0 ? 'Pickup Date' : 'Depart Date'
    return this.getPickupDateForStop(stop) ? label : ''
  }

  handleChangeStopAddress(address: Address, idx: number = null): void {
    if (idx == null) {
      widget.setFinalDropoffAddress(address)
      return
    }

    const oldTimeZone = widget.stops[idx].address?.timeZone || dayjs.tz.guess()
    const newTimeZone = address?.timeZone
    let pickupDatetime = widget.stops[idx].pickupDatetime
    let pickupDate = widget.stops[idx].pickupDate
    let pickupTime = widget.stops[idx].pickupTime

    if (pickupDatetime) {
      const oldPickupDatetime = dayjs(pickupDatetime)
        .tz(oldTimeZone)
        .format('YYYY-MM-DDTHH:mm:ss')
      const newPickupDatetime = dayjs(oldPickupDatetime).tz(newTimeZone, true)
      pickupDatetime = newPickupDatetime.toISOString()
      pickupDate = newPickupDatetime.format('YYYY-MM-DD')
      pickupTime = newPickupDatetime.format('HH:mm')
    }

    widget.updateStop({
      idx,
      stop: new Stop({ address, pickupDatetime, pickupDate, pickupTime }),
    })
  }

  handleChangeStopDate(date: string, idx: number): void {
    const time = widget.stops[idx].pickupTime || '00:00:00'
    const timeZone = widget.stops[idx]?.address?.timeZone || dayjs.tz.guess()
    const dateTime = date ? `${date}T${time}` : null
    const pickupDatetime = date
      ? dayjs(dateTime).tz(timeZone, true).toISOString()
      : null
    widget.updateStop({
      idx,
      stop: new Stop({ pickupDate: date, pickupTime: time, pickupDatetime }),
    })
  }

  handleChangeStopTime(time: string, idx: number): void {
    const date = widget.stops[idx].pickupDate || dayjs().format('YYYY-MM-DD')
    const timeZone = widget.stops[idx]?.address?.timeZone || dayjs.tz.guess()
    const dateTime = `${date}T${time}`
    const pickupDatetime = dayjs(dateTime).tz(timeZone, true).toISOString()

    widget.updateStop({
      idx,
      stop: new Stop({ pickupTime: time, pickupDate: date, pickupDatetime }),
    })
  }

  getDropoffDateForStop(stop: Stop): string {
    const ts = stop.dropoffDatetime
    const timeZone = stop.address?.timeZone || dayjs.tz.guess()
    const weekday = dayjs(ts).tz(timeZone).format('ddd')
    const date = this.formatShortDate(ts, { tz: timeZone })
    const time = this.formatShortTime(ts, {
      tz: timeZone,
      showMeridianUpper: true,
    })
    return ts ? `${weekday}, ${date}, ${time}` : '--'
  }

  getPickupDateForStop(stop: Stop): string {
    const ts = stop.pickupDatetime
    const timeZone = stop.address?.timeZone || dayjs.tz.guess()
    return ts ? dayjs(ts).tz(timeZone).format('YYYY-MM-DD') : ''
  }

  getPickupTimeForStop(stop: Stop): string {
    const ts = stop.pickupDatetime
    const timeZone = stop.address?.timeZone || dayjs.tz.guess()
    return ts ? dayjs(ts).tz(timeZone).format('HH:mm') : ''
  }

  getDefaultDateForStop(stopIdx: number): string {
    return (
      widget.stops[stopIdx].dropoffDatetime ||
      (stopIdx !== 0 &&
        (widget.stops[stopIdx - 1].pickupDatetime ||
          widget.stops[stopIdx - 1].dropoffDatetime)) ||
      null
    )
  }

  prevDateValue(idx: number): string {
    return idx === 0
      ? dayjs().toISOString()
      : widget.stops[idx - 1].pickupDatetime || dayjs().toISOString()
  }

  async next(): Promise<void> {
    if (!(await validateRules(this))) {
      return
    }
    widget.nextPage()
  }
}
