var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"margin-b-8 margin-t-4"},[_c('div',{staticClass:"d-flex"},[_c('p',{staticClass:"margin-b-2 body-bold"},[_vm._v(" Already have an itinerary? Upload it here. ")]),_c('CUTooltip',{attrs:{"tooltip-text":_vm.tooltipText,"min-width":"200px","bottom":""}},[_c('CUIcon',{staticClass:"cursor-pointer d-flex align-center margin-l-1",style:({ marginTop: '-1px' }),attrs:{"color":"text-gray-3","width":"18px"}},[_vm._v(" info ")])],1)],1),(
      _vm.widget.attachedFiles.length < _vm.widget.maxFilesAllowed &&
      !_vm.displayUploadInput
    )?_c('CUButton',{key:"add-button",attrs:{"id":"quote-widget-display-upload-input","color":_vm.widget.primaryColor,"opaque":"","outlined":"","unset-width":"","small":""},on:{"click":_vm.toggleDisplayUploadInput}},[_c('CUIcon',{attrs:{"width":"20","height":"20","color":_vm.widget.primaryColor}},[_vm._v(" upload_paperclip ")]),_c('span',[_vm._v("Attach Itinerary")])],1):_vm._e(),_c('v-slide-y-transition',{attrs:{"hide-on-leave":""}},[(_vm.displayUploadInput)?_c('div',[_c('button',{key:"close-button",staticClass:"d-flex align-center margin-b-2 font-14 font-bold cursor-pointer",style:({
          color: _vm.widget.primaryColor,
        }),attrs:{"id":"quote-widget-hide-itinerary-upload"},on:{"click":function($event){$event.preventDefault();return _vm.toggleDisplayUploadInput.apply(null, arguments)}}},[_c('CUIcon',{key:"close-icon",staticClass:"margin-r-2",style:({
            marginTop: '-2px',
          }),attrs:{"height":"20px","width":"20px","color":_vm.widget.primaryColor}},[_vm._v(" close ")]),_vm._v(" Close ")],1),(_vm.displayUploadInput)?_c('div',{staticClass:"w-full d-flex flex-column align-center justify-center position-relative cursor-pointer border-radius-30 border-2 border-dashed padding-x-3 margin-b-3",class:{
          'h-100': _vm.$vuetify.breakpoint.width >= 650,
          'h-160': _vm.$vuetify.breakpoint.width < 650,
        },style:({
          color: _vm.widget.primaryColor,
          borderColor: _vm.widget.primaryColor,
        }),attrs:{"id":"quote-widget-upload-input"},on:{"dragover":function($event){$event.preventDefault();},"drop":function($event){$event.preventDefault();return _vm.handleDrop.apply(null, arguments)}}},[_c('input',{staticClass:"opacity-0 w-full h-full position-absolute top-0 left-0 cursor-pointer",attrs:{"type":"file","multiple":"","accept":"image/png,application/pdf,image/jpeg"},on:{"change":_vm.handleBrowse}}),(!_vm.error)?_c('CUIcon',{attrs:{"color":_vm.widget.primaryColor,"height":"40","width":"40"}},[_vm._v(" add ")]):_vm._e(),(!_vm.error)?_c('span',{staticClass:"text-gray-text-dark body-2-regular margin-t-3",class:{
            'text-center padding-x-5': _vm.$vuetify.breakpoint.width < 650,
          }},[_vm._v(" Drop up to "+_vm._s(_vm.fileSlotsAvailableCount)+" "+_vm._s(_vm.fileSlotsAvailableCount > 1 ? 'files' : 'file')+" here or "),_c('span',{style:({
              color: _vm.widget.primaryColor,
            })},[_vm._v(" browse files ")]),_vm._v(" on your device ")]):_vm._e(),(_vm.error)?_c('span',{staticClass:"text-error font-14"},[_vm._v(" "+_vm._s(_vm.error)+" ")]):_vm._e()],1):_vm._e()]):_vm._e()]),_vm._l((_vm.widget.attachedFiles),function(file,fileIndex){return _c('div',{key:`itinerary-attachment-${file}-${fileIndex}`,staticClass:"d-flex justify-space-between align-center border-radius-8 padding-a-1 background-bg-gray margin-t-3"},[_c('div',{staticClass:"padding-l-1 overflow-hidden text-overflow-ellipsis font-14",style:({
        width: 'calc(100% - 25px)',
        color: _vm.widget.primaryColor,
      })},[_vm._v(" "+_vm._s(file.name)+" ")]),_c('button',{key:`close-button-${file.name}`,staticClass:"d-flex align-center margin-r-1",attrs:{"id":"quote-widget-upload-remove-file"},on:{"click":function($event){return _vm.handleRemoveFile(fileIndex)}}},[_c('CUIcon',{key:"driver-details-document-upload-close",staticClass:"cursor-pointer",attrs:{"color":"gray-icon-dark","width":"16px","height":"16px"}},[_vm._v(" close ")])],1)])})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }