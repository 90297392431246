
import widget from '@/store/modules/widget'
import { Vue, Component } from 'vue-property-decorator'

@Component({})
export default class WidgetProgressTimeline extends Vue {
  widget = widget
  STEP_COUNT = 4

  get page(): number {
    if (widget.page > widget.pageCount && !widget.success) {
      return widget.pageCount
    }
    return widget.page
  }

  get style(): Record<string, unknown> {
    const primary = widget.primaryColor
    return {
      '--primary': primary,
    }
  }

  isCompleted(i: number): boolean {
    return i < this.page
  }
}
