
import { Vue, Component, Prop } from 'vue-property-decorator'
import widget from '@/store/modules/widget'
@Component
export default class GenericComponent extends Vue {
  @Prop({ required: true }) readonly icon!: string
  @Prop({ required: true }) readonly title!: string
  @Prop({ required: true }) readonly description!: string
  @Prop({ default: false }) readonly selected!: boolean
  @Prop({ default: false }) readonly sm!: boolean

  widget = widget

  get cardStyle(): Record<string, string> {
    if (!this.selected) {
      return {}
    }

    return {
      'background-color': this.widget.primaryColor20,
      borderColor: `${this.widget.primaryColor}`,
    }
  }

  get iconStyle(): Record<string, string> {
    if (!this.selected) {
      return {}
    }

    return {
      'background-color': this.widget.primaryColor,
    }
  }

  get iconColor(): string {
    if (!this.selected) {
      return 'text-gray-3'
    }

    return 'white'
  }
}
