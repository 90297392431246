
import { Vue, Component } from 'vue-property-decorator'
import {
  httpHelper,
  phoneFormatFilter,
  sanitizeStringInput,
} from '@/utils/string'
import { staticResource } from '@/utils/env'
import widget from '@/store/modules/widget'

@Component
export default class WidgetAppBar extends Vue {
  phoneFormatFilter = phoneFormatFilter
  staticResource = staticResource
  widget = widget
  phone = null

  get hasWebsiteUrl(): boolean {
    return !!this.widget?.companyWebsiteUrl
  }

  openWebsiteLink(): void {
    if (this.hasWebsiteUrl) {
      window.open(httpHelper(this.widget.companyWebsiteUrl), '_blank')
    }
  }

  setPhone(): void {
    const phoneNumber = sanitizeStringInput(this.$route.query?.phone as string)
    if (phoneNumber) {
      this.phone = this.phoneFormatFilter(phoneNumber)
      return
    }
    this.phone = this.phoneFormatFilter(this.widget.companyPhone)
  }

  mounted(): void {
    this.setPhone()
  }
}
