
import { Vue, Component } from 'vue-property-decorator'
import widget from '@/store/modules/widget'
import { validateRules, validationRules } from '@/utils/rules'
@Component({})
export default class WidgetEventTypes extends Vue {
  widget = widget
  rules = validationRules

  async submit(): Promise<void> {
    if (!(await validateRules(this))) {
      return
    }
    this.$emit('submit')
  }
}
