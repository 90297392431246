
import app from '@/store/modules/app'
import widget from '@/store/modules/widget'
import { Vue, Component } from 'vue-property-decorator'
import WidgetAppBar from '@/components/WidgetAppBar.vue'
import WidgetTripDetails from '@/components/WidgetTripDetails.vue'
import WidgetTripItinerary from '@/components/WidgetTripItinerary.vue'
import WidgetOptionalSelections from '@/components/WidgetOptionalSelections.vue'
import WidgetConfirmRequest from '@/components/WidgetConfirmRequest.vue'
import WidgetEventTypes from '@/components/WidgetEventTypes.vue'
import WidgetThankYou from '@/components/WidgetThankYou.vue'
import widgetService from '@/services/widget'
import WidgetProgressTimeline from '@/components/WidgetProgressTimeline.vue'
import { WidgetSubmitPayload } from '@/models/dto/Widget'
import { useFavicon } from '@vueuse/core'
import {
  isAutoEnvironment,
  isDevEnvironment,
  staticResource,
} from '@/utils/env'
import auth from '@/store/modules/auth'
import { sanitizeStringInput } from '@/utils/string'
import { EventBus } from '@/utils/eventBus'

@Component({
  components: {
    WidgetAppBar,
    WidgetTripDetails,
    WidgetTripItinerary,
    WidgetOptionalSelections,
    WidgetConfirmRequest,
    WidgetThankYou,
    WidgetProgressTimeline,
    WidgetEventTypes,
  },
})
export default class Widget extends Vue {
  loading = true
  widget = widget

  get sm(): boolean {
    return this.$vuetify.breakpoint.width < 650
  }

  async initStore(companyString: string): Promise<void> {
    try {
      const tripTypeRequest = widgetService.tripTypes()
      const companyRequest = widgetService.companyByIdentifier(companyString)

      const [tripTypes, company] = await Promise.all([
        tripTypeRequest,
        companyRequest,
      ])

      widget.setIdentifier(companyString)
      widget.setCompany(company.data.company)
      auth.setShouldDisplayDayLeading(company.data.company?.defaultDateFormat)

      // Set trip types
      const filteredTripTypes = tripTypes.data.filter((t) =>
        ['one_way', 'round_trip', 'shuttle'].includes(t.key)
      )
      const noneType = tripTypes.data.find((t) => t.key === 'none')
      if (noneType) {
        noneType.label = 'Other'
        filteredTripTypes.push(noneType)
      }
      widget.setTripTypes(filteredTripTypes)

      // Set branding on tab
      window.document.title = `${company.data.company.name} - Quote Request`

      const faviconFile =
        company.data.company.faviconUrl || company.data.company.darkLogoUrl
      if (faviconFile) {
        const icon = useFavicon()
        const faviconUrl = staticResource(faviconFile)
        icon.value = faviconUrl
      }

      this.loading = false
    } catch (e) {
      console.log(e)
      this.$router.push({
        name: 'not-found',
      })
    }
  }

  async submit(): Promise<void> {
    // Submit quote data
    widget.setLoading(true)

    try {
      const token = await this.recaptcha()
      if (!token) {
        widget.setLoading(false)
        return
      }

      const vehicles = widget.vehicles.map((v) => ({
        quantity: v.quantity,
        vehicleTypeId: v.id,
      }))

      let itineraries = []
      if (widget.attachedFiles?.length) {
        const formData = new FormData()
        for (const file of widget.attachedFiles) {
          formData.append(`files`, file)
        }

        const res = await widgetService.uploadItineraries(formData)
        itineraries = res.data?.itineraries
      }
      const payload: WidgetSubmitPayload = {
        customer: widget.customer,
        tripTypeId: widget.tripTypeId,
        eventTypeId: widget.eventTypeId,
        passengerCount: Number(widget.passengerCount),
        stops: widget.allStops.stops,
        vehicles,
        note: widget.note,
        itineraries,
        token,
        tag: widget.tag,
      }
      await widgetService.submit(widget.identifier, payload)
      widget.setSuccess(true)
      widget.lastPage()
    } catch (e: any) {
      console.error(e)
      if (e?.response?.data?.message?.includes('Failed to verify recaptcha')) {
        EventBus.$emit(
          'snackbar:error',
          'Something went wrong. Refresh to try again or use a different browser.'
        )
      }
    } finally {
      widget.setLoading(false)
    }
  }

  async recaptcha(): Promise<string> {
    if (!app.isSystemParameterTrue('shouldValidateRecaptchaToken')) {
      return 'not in use'
    }
    try {
      await this.$recaptchaLoaded()
      return await this.$recaptcha('submit')
    } catch (e) {
      console.error(e)
      EventBus.$emit(
        'snackbar:error',
        'Something went wrong. Refresh to try again or use a different browser.'
      )
      return null
    }
  }

  mounted(): void {
    if (
      !isDevEnvironment() &&
      !isAutoEnvironment() &&
      !app.isCustomersEnvironment
    ) {
      this.$router.push({ name: 'home' })
    }

    const companyString = this.$route.params?.company
    const tagString = sanitizeStringInput(this.$route.query?.tag as string)

    widget.setTag(tagString)
    this.initStore(companyString)
  }
}
