
import { Vue, Component } from 'vue-property-decorator'
import widget from '@/store/modules/widget'
import CUCounter from '@/components/CUCounter.vue'
import WidgetPageHeader from '@/components/WidgetPageHeader.vue'
import { validateRules, validationRules } from '@/utils/rules'

@Component({
  components: {
    CUCounter,
    WidgetPageHeader,
  },
})
export default class WidgetOptionalSelections extends Vue {
  widget = widget
  rules = validationRules

  async next() {
    if (!(await validateRules(this))) {
      return
    }
    widget.nextPage()
  }
}
