var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-form',{ref:"form"},[_c('WidgetPageHeader',{attrs:{"title":"Let's get started!","hide-back-btn":""}}),_c('h3',{staticClass:"font-16 font-bold"},[_vm._v("What type of trip are you planning?")]),_c('span',{staticClass:"font-12 text-error",class:{
      'opacity-0': _vm.hideTripTypeError,
    }},[_vm._v(" Trip type is required ")]),_c('div',{staticClass:"trip-type-cards margin-t-2"},[_c('div',{staticClass:"d-flex justify-space-between",class:{
        'flex-column margin-b-2': _vm.$vuetify.breakpoint.width < 650,
        'margin-b-4': _vm.$vuetify.breakpoint.width >= 650,
      },style:({ gap: _vm.$vuetify.breakpoint.width < 650 ? '8px' : '16px' })},[_c('WidgetTripTypeCard',{staticClass:"flex-basis-one-half",attrs:{"icon":"one_way","title":"One Way","description":"Going from point A to point B.","selected":_vm.tripTypeId === 1,"sm":_vm.$vuetify.breakpoint.width < 650},on:{"click":function($event){return _vm.widget.setTripType(1)}}}),_c('WidgetTripTypeCard',{staticClass:"flex-basis-one-half",attrs:{"icon":"round_trip","title":"Round Trip","description":"We’ll drop you off back at the pickup.","selected":_vm.tripTypeId === 2,"sm":_vm.$vuetify.breakpoint.width < 650},on:{"click":function($event){return _vm.widget.setTripType(2)}}})],1),_c('div',{staticClass:"d-flex justify-space-between margin-b-4",class:{ 'flex-column': _vm.$vuetify.breakpoint.width < 650 },style:({ gap: _vm.$vuetify.breakpoint.width < 650 ? '8px' : '16px' })},[_c('WidgetTripTypeCard',{staticClass:"flex-basis-one-half",attrs:{"icon":"shuttle","title":"Shuttle","description":"Continuous route between stops.","selected":_vm.tripTypeId === 5,"sm":_vm.$vuetify.breakpoint.width < 650},on:{"click":function($event){return _vm.widget.setTripType(5)}}}),_c('WidgetTripTypeCard',{staticClass:"flex-basis-one-half",attrs:{"icon":"other_trip_type","title":"Other","description":"Not sure? No problem.","selected":_vm.tripTypeId === 0,"sm":_vm.$vuetify.breakpoint.width < 650},on:{"click":function($event){return _vm.widget.setTripType(0)}}})],1)]),_c('h3',{staticClass:"font-16 font-bold margin-t-4 margin-b-2"},[_vm._v(" How many passengers do you have? ")]),_c('div',{staticClass:"d-flex margin-b-4",style:({ gap: '8px' })},[_c('CUTextField',{staticClass:"margin-b-5",class:{
        'flex-basis-one-half': _vm.$vuetify.breakpoint.width >= 650,
        'flex-basis-full': _vm.$vuetify.breakpoint.width < 650,
      },attrs:{"id":"quote-widget-passenger-count","value":_vm.widget.passengerCount,"widget":"","type":"number","hide-spin-buttons":"","border-color":_vm.widget.primaryColor,"label":_vm.widget.passengerCount && 'Passengers',"placeholder":"Passengers","rules":_vm.rules.required('Passengers are required')},on:{"input":_vm.widget.setPassengerCount}}),(_vm.$vuetify.breakpoint.width >= 650)?_c('div',{staticClass:"flex-basis-one-half"}):_vm._e()],1),_c('div',{staticClass:"d-flex justify-end padding-b-4"},[_c('CUButton',{staticClass:"text-white flex-grow-1",class:{
        'max-w-252 w-252': _vm.$vuetify.breakpoint.width >= 650,
      },attrs:{"id":"quote-widget-to-optional-selections","color":_vm.widget.primaryColor,"unset-width":"","loading":_vm.widget.loading},on:{"click":_vm.next}},[_vm._v(" Next ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }