
import { Component, Vue } from 'vue-property-decorator'
import widget from '@/store/modules/widget'

@Component
export default class WidgetItineraryUpload extends Vue {
  widget = widget
  displayUploadInput = false
  error = ''

  get fileSlotsAvailableCount(): number {
    return widget.maxFilesAllowed - widget.attachedFiles.length
  }

  handleBrowse(event: Event): void {
    const target = event.target as HTMLInputElement
    if (!this.validateUpload(target.files)) {
      return
    }
    for (const file of target.files) {
      widget.addItineraryAttachment(file)
    }
    this.displayUploadInput = false
  }

  handleDrop(event: DragEvent): void {
    if (!this.validateUpload(event.dataTransfer.files)) {
      return
    }
    for (const file of event.dataTransfer.files) {
      widget.addItineraryAttachment(file)
    }
    this.displayUploadInput = false
  }

  get tooltipText(): string {
    if (widget.companyEmail) {
      return `If your file is larger than 10MB or not pdf, png, jpeg, please send it to ${widget.companyEmail}`
    }
    return 'If your file is larger than 10MB or not pdf, png, jpeg, please reach out to us directly through email.'
  }

  displayError(message: string): void {
    this.error = message
    setTimeout(() => (this.error = ''), 3000)
  }

  toggleDisplayUploadInput(): void {
    this.displayUploadInput = !this.displayUploadInput
  }

  validateUpload(files: any): boolean {
    // Too many files uploaded
    if (files.length + widget.attachedFiles.length > widget.maxFilesAllowed) {
      this.displayError(
        `Too many files selected, please select up to ${this.fileSlotsAvailableCount} more.`
      )
      return false
    }

    for (const file of files) {
      // Checking for files that are > 10 MB
      if (file.size > 10000000) {
        this.displayError(
          'One or more files is too big, please keep file sizes under 10MB.'
        )
        return false
      }

      // Checking for files that are not type PDF, PNG, or JPEG
      if (
        file.type !== 'image/png' &&
        file.type !== 'application/pdf' &&
        file.type !== 'image/jpeg'
      ) {
        this.displayError(
          'One or more files is not the correct format, please use PDF, PNG, or JPEG.'
        )
        return false
      }
    }

    return true
  }

  handleRemoveFile(fileIndex: number): void {
    widget.removeItineraryAttachment(fileIndex)
  }
}
