var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',_vm._g({staticClass:"widget--trip-type-card cursor-pointer d-flex border-x-1 border-y-1 border-solid align-center padding-y-4 border-radius-10 transition-ease transition-duration-200",class:{
    'hover:background-bg-gray-2 border-bg-gray-2': !_vm.selected,
    'flex-row align-center': _vm.sm,
    'flex-column justify-center ': !_vm.sm,
  },style:(_vm.cardStyle)},_vm.$listeners),[_c('div',{staticClass:"d-flex align-center justify-center h-48 w-48 border-radius-round transition-ease transition-duration-200",class:{
      'background-bg-gray-1': !_vm.selected,
      'margin-l-5 margin-r-2': _vm.sm,
      'margin-b-2': !_vm.sm,
    },style:(_vm.iconStyle)},[_c('CUIcon',{attrs:{"width":"24","height":"24","color":_vm.iconColor}},[_vm._v(_vm._s(_vm.icon))])],1),_c('div',[_c('p',{staticClass:"body-semibold text-text-dark-gray",class:{
        'text-center': !_vm.sm,
      }},[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c('p',{staticClass:"text-text-gray-3 font-14"},[_vm._v(_vm._s(_vm.description))])])])
}
var staticRenderFns = []

export { render, staticRenderFns }