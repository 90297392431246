
import DateMixin from '@/mixins/DateMixin'
import Component, { mixins } from 'vue-class-component'
import widget from '@/store/modules/widget'
import { Stop } from '@/models/dto'
import ReservationTrackingMap from '@/components/ReservationTrackingMap.vue'
import { validateRules, validationRules } from '@/utils/rules'
import TripItinerary from '@/components/TripItinerary.vue'
import WidgetPageHeader from '@/components/WidgetPageHeader.vue'
import { isoToShortMonthDay } from '@/utils/date'
import dayjs from 'dayjs'

@Component({
  components: {
    ReservationTrackingMap,
    TripItinerary,
    WidgetPageHeader,
  },
})
export default class WidgetConfirmRequest extends mixins(DateMixin) {
  widget = widget
  rules = validationRules
  isoToShortMonthDay = isoToShortMonthDay

  displayShortTime(time: string): string {
    time = dayjs(time, 'hh:mm').toISOString()
    return this.formatShortTime(time, {
      showMeridianLower: true,
    })
  }

  get mapStops(): Stop[] {
    return [...widget.stops, new Stop({ address: widget.finalDropoffAddress })]
  }

  get shouldCollectEventTypes(): boolean {
    return !!widget.eventTypes.length
  }

  async next(): Promise<void> {
    if (!(await validateRules(this))) {
      return
    }

    if (!this.shouldCollectEventTypes) {
      this.$emit('submit')
      return
    }
    widget.nextPage()
  }
}
